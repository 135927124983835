// Selection of invisible characters
const invisibleChars = ['\u200B', '\u200C', '\u200D'];

// Selection of homoglyph mappings
const homoglyphChars = { '!': 'ǃ', 'A': 'Α', 'B': 'Β', 'C': 'С', 'E': 'Ε', 'H': 'Η', 'I': 'Ι', 'J': 'Ј', 'K': 'Κ', 'M': 'Μ', 'N': 'Ν', 'O': 'Ο', 'P': 'Ρ', 'S': 'Ѕ', 'T': 'Τ', 'X': 'Χ', 'Y': 'Υ', 'Z': 'Ζ', 'a': 'а', 'c': 'с', 'd': 'ԁ', 'e': 'е', 'h': 'һ', 'i': 'і', 'j': 'ϳ', 'o': 'ο', 'p': 'р', 's': 'ѕ', 'x': 'х', 'y': 'у', 'Æ': 'Ӕ', 'Ð': 'Đ', 'æ': 'ӕ', 'ĸ': 'к', 'Ƃ': 'Б', 'Ə': 'Ә', 'Ɵ': 'Ө', 'Ʃ': 'Σ', 'ǝ': 'ə', 'Ʌ': 'Λ', 'ə': 'ә', 'ɛ': 'ε', 'ɩ': 'ι', 'ɪ': 'ӏ', 'ɵ': 'ө', 'ʒ': 'ӡ', 'ʙ': 'в', 'ʜ': 'н', 'Γ': 'Г', 'Π': 'П', 'α': '⍺', 'ι': '⍳', 'ρ': '⍴', 'ω': '⍵', 'г': 'ᴦ', 'л': 'ᴫ', 'п': 'ᴨ', 'ဝ': '၀', 'អ': 'ឣ', 'ᠵ': 'ᡕ', 'ᦞ': '᧐', 'ᦱ': '᧑', 'ᩅ': '᪀', 'ᬍ': '᭒', 'ᬑ': '᭓', 'ᬨ': '᭘', '᭐': '᭜', 'ᴍ': 'м', 'ᴘ': 'ᴩ', 'ᴛ': 'т', 'Ⱨ': 'Ң', 'Ⱪ': 'Қ', '꧐': '꧆', '𐎂': '𐏑', '𐎓': '𐏓', '𐎚': '𒀸', '𐒆': '𐒠' };

// List of Bidi override characters
const reorderingChars = ['\u202A', '\u202B', '\u202D', '\u202E', '\u202C', '\u2066', '\u2067', '\u2068', '\u2069', '\u200E', '\u200F', '\u061C'];

// Selection of deletion characters
const deletionChars = ['\x08', '\x7F'];

export {invisibleChars, homoglyphChars, reorderingChars, deletionChars};